import { call, put, takeLatest } from 'redux-saga/effects';
import mutationRequest from '../../../../../../utils/mutationRequest';
import queryRequest from '../../../../../../utils/queryRequest';
import {
  CREATE_USE_CASE_MUTATION,
  DELETE_USE_CASE_MUTATION,
  UPDATE_USE_CASE_MUTATION,
  DELETE_PIPELINE_MUTATION,
  CREATE_PIPELINE_PROCESSOR_MUTATION,
  DELETE_PIPELINE_PROCESSOR_MUTATION,
  UPDATE_SEQNUM_PIPELINE_PROCESSORS_MUTATION
} from './mutations';
import {
  GET_COUNTRIES,
  GET_OFFENSES,
  GET_PARKING_RIGHT_PROVIDERS
} from '../../../../../queries/SharedQueries';
import {
  CAMERAS_QUERY,
  PIPELINE_QUERY,
  PROCESSOR_QUERY,
  USE_CASES_QUERY,
  USE_CASE_DETAILS_QUERY,
  USE_CASE_ZONES_QUERY
} from './queries';
import { useCasesActions } from './slice';

function* fetchUseCases() {
  yield put(useCasesActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, USE_CASES_QUERY, {});
    yield put(useCasesActions.storeUseCases(data.useCases));
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* fetchUseCase(action) {
  yield put(useCasesActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, USE_CASE_DETAILS_QUERY, {
      useCaseId: action.payload
    });
    yield put(useCasesActions.storeUseCase(data.useCases[0]));
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* fetchUseCaseZones() {
  try {
    const { data } = yield call(queryRequest, USE_CASE_ZONES_QUERY, {});
    yield put(useCasesActions.storeUseCaseZones(data.useCases));
  } catch (e) {
    console.error(e);
  }
}

function* fetchSelectedUseCaseZone(action) {
  try {
    const { data } = yield call(queryRequest, USE_CASE_ZONES_QUERY, {
      search: { id: [action.payload] }
    });
    yield put(useCasesActions.storeSelectedUseCaseZone(data.useCases[0].zones));
  } catch (e) {
    console.error(e);
  }
}

function* createUseCase(action) {
  yield put(useCasesActions.setLoading(true));

  try {
    yield call(mutationRequest, CREATE_USE_CASE_MUTATION, action.payload);

    yield fetchUseCases();
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* updateUseCase(action) {
  yield put(useCasesActions.setLoading(true));

  try {
    const { data } = yield call(mutationRequest, UPDATE_USE_CASE_MUTATION, action.payload);
    yield put(useCasesActions.storeUseCase(data.useCaseUpdate));

    yield fetchUseCases();
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* deleteUseCase(action) {
  yield put(useCasesActions.setLoading(true));

  try {
    yield call(mutationRequest, DELETE_USE_CASE_MUTATION, { id: action.payload });

    yield fetchUseCases();
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* fetchCameras(action) {
  yield put(useCasesActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, CAMERAS_QUERY, {
      search: { copPermissionBasedScope: false },
      useCaseId: action.payload
    });
    yield put(useCasesActions.storeCameras(data.copCameras));
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* deletePipeline(action) {
  yield put(useCasesActions.setLoading(true));

  try {
    yield call(mutationRequest, DELETE_PIPELINE_MUTATION, { id: action.payload.pipelineId });
    yield fetchUseCase({ payload: action.payload.useCaseId });
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* fetchCountries() {
  yield put(useCasesActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, GET_COUNTRIES, {});
    yield put(
      useCasesActions.storeCountries(
        data.countries?.map(country => ({ id: country.code, dbId: country.id, name: country.code }))
      )
    );
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* fetchParkingRightProviders() {
  yield put(useCasesActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, GET_PARKING_RIGHT_PROVIDERS, {});
    yield put(
      useCasesActions.storeParkingRightProviders(
        data.parkingRightProviders?.map(provider => ({ id: provider.name, name: provider.name }))
      )
    );
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* deletePipelineProcessor(action) {
  yield put(useCasesActions.setLoading(true));

  try {
    yield call(mutationRequest, DELETE_PIPELINE_PROCESSOR_MUTATION, {
      pipelineProcessorId: action.payload.pipelineProcessorId
    });
    yield fetchUseCase({ payload: action.payload.useCaseId });
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* createPipelineProcessor(action) {
  yield put(useCasesActions.setLoading(true));

  try {
    yield call(mutationRequest, CREATE_PIPELINE_PROCESSOR_MUTATION, action.payload);

    yield fetchUseCase({ payload: action.payload.useCaseId });
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* updateSeqnumPipelineProcessors(action) {
  yield put(useCasesActions.setLoading(true));

  try {
    yield call(mutationRequest, UPDATE_SEQNUM_PIPELINE_PROCESSORS_MUTATION, action.payload);
    yield fetchUseCase({ payload: action.payload.useCaseId });
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

function* fetchPipeline(action) {
  try {
    const { data } = yield call(queryRequest, PIPELINE_QUERY, action.payload);
    yield put(useCasesActions.storePipeline(data.useCases[0]?.pipelines[0]));
  } catch (e) {
    console.error(e);
  }
}

function* fetchProcessor(action) {
  try {
    const { data } = yield call(queryRequest, PROCESSOR_QUERY, action.payload);
    yield put(
      useCasesActions.storeProcessor(data.useCases[0]?.pipelines[0]?.pipelineProcessors[0])
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchOffenseCodes() {
  yield put(useCasesActions.setLoading(true));

  try {
    const { data } = yield call(queryRequest, GET_OFFENSES, {
      variables: { code: '', type: null },
      limit: 100
    });
    yield put(useCasesActions.storeOffenseCodes(data.offenses));
  } catch (e) {
    console.error(e);
  }

  yield put(useCasesActions.setLoading(false));
}

export function* useCasesSaga() {
  yield takeLatest(useCasesActions.fetchUseCases.type, fetchUseCases);
  yield takeLatest(useCasesActions.fetchUseCase.type, fetchUseCase);
  yield takeLatest(useCasesActions.fetchUseCaseZones.type, fetchUseCaseZones);
  yield takeLatest(useCasesActions.fetchSelectedUseCaseZone.type, fetchSelectedUseCaseZone);
  yield takeLatest(useCasesActions.createUseCase.type, createUseCase);
  yield takeLatest(useCasesActions.updateUseCase.type, updateUseCase);
  yield takeLatest(useCasesActions.deleteUseCase.type, deleteUseCase);
  yield takeLatest(useCasesActions.fetchCameras.type, fetchCameras);
  yield takeLatest(useCasesActions.deletePipeline.type, deletePipeline);
  yield takeLatest(useCasesActions.fetchCountries.type, fetchCountries);
  yield takeLatest(useCasesActions.fetchPipeline.type, fetchPipeline);
  yield takeLatest(useCasesActions.fetchProcessor.type, fetchProcessor);
  yield takeLatest(useCasesActions.fetchOffenseCodes.type, fetchOffenseCodes);

  yield takeLatest(useCasesActions.createPipelineProcessor.type, createPipelineProcessor);
  yield takeLatest(useCasesActions.deletePipelineProcessor.type, deletePipelineProcessor);
  yield takeLatest(
    useCasesActions.updateSeqnumPipelineProcessors.type,
    updateSeqnumPipelineProcessors
  );
  yield takeLatest(useCasesActions.fetchParkingRightProviders.type, fetchParkingRightProviders);
}
