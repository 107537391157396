export const SET_OBSERVATION_METADATA = 'SET_OBSERVATION_METADATA';

export const ADD_OBSERVATION_RELATION_METADATA = 'ADD_OBSERVATION_RELATION_METADATA';
export const ADD_OBSERVATION_ACTION_METADATA = 'ADD_OBSERVATION_ACTION_METADATA';
export const LOAD_OBSERVATION_METADATA = 'LOAD_OBSERVATION_METADATA';
export const REMOVE_OBSERVATION_RELATION_METADATA = 'REMOVE_OBSERVATION_RELATION_METADATA';
export const REMOVE_OBSERVATION_METADATA = 'REMOVE_OBSERVATION_METADATA';
export const CLEAN_METADATA = 'CLEAN_METADATA';
export const SET_CURRENT_OBSERVATION = 'SET_CURRENT_OBSERVATION';

export const SET_OBSERVATIONS_FILTERS = 'SET_OBSERVATIONS_FILTERS';

export const ADD_USER = 'ADD_USER';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';

export const SET_PREVIOUS_BOUNDS = 'SET_PREVIOUS_BOUNDS';
