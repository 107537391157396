import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useCasesSaga } from './saga';

const initialState = {
  useCases: [],
  useCaseZones: [],
  selectedUseCase: null,
  cameras: [],
  countries: [],
  parkingRightProviders: [],
  selectedPipeline: null,
  selectedProcessor: null,
  selectedUseCaseZone: null,
  loading: false,
  offenseCodes: [],
  errors: null
};

const slice = createSlice({
  name: 'useCases',
  initialState,
  reducers: {
    fetchUseCases: () => {},
    storeUseCases: (state, action) => {
      state.useCases = action.payload;
    },
    fetchUseCase: (_state, _action) => {},
    storeUseCase: (state, action) => {
      state.selectedUseCase = {
        ...state.selectedUseCase,
        ...action.payload
      };
    },
    clearSelectedUseCase: (state, _action) => {
      state.selectedUseCase = initialState.selectedUseCase;
    },
    fetchUseCaseZones: (_state, _action) => {},
    storeUseCaseZones: (state, action) => {
      state.useCaseZones = action.payload;
    },
    fetchSelectedUseCaseZone: (_state, _action) => {},
    storeSelectedUseCaseZone: (state, action) => {
      state.selectedUseCase = {
        ...state.selectedUseCase,
        zones: action.payload
      };
    },
    createUseCase: (_state, _action) => {},
    updateUseCase: (_state, _action) => {},
    deleteUseCase: (_state, _action) => {},
    fetchCameras: (_state, _action) => {},
    storeCameras: (state, action) => {
      state.cameras = action.payload;
    },
    fetchPipeline: (_state, _action) => {},
    storePipeline: (state, action) => {
      state.selectedPipeline = action.payload;
    },
    deletePipeline: (_state, _action) => {},
    storePipelineProcessors: (state, action) => {
      state.selectedPipeline.pipelineProcessors = action.payload;
    },
    createPipelineProcessor: (_state, _action) => {},
    updatePipelineProcessor: (_state, _action) => {},
    updateSeqnumPipelineProcessors: (_state, _action) => {},
    deletePipelineProcessor: (_state, _action) => {},
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    fetchProcessor: (_state, _action) => {},
    storeProcessor: (state, action) => {
      state.selectedProcessor = action.payload;
    },
    fetchCountries: () => {},
    storeCountries: (state, action) => {
      state.countries = action.payload;
    },
    fetchOffenseCodes: () => {},
    storeOffenseCodes: (state, action) => {
      state.offenseCodes = action.payload;
    },
    fetchParkingRightProviders: () => {},
    storeParkingRightProviders: (state, action) => {
      state.parkingRightProviders = action.payload;
    },
    storeUseCaseZone: (state, action) => {
      state.selectedUseCaseZone = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    clearData: () => initialState
  }
});

export const useUseCasesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: useCasesSaga });
  return { actions: slice.actions };
};

export const selectUseCasesState = state => state.useCases;

export const { actions: useCasesActions, reducer: useCasesReducer } = slice;
